import { gql } from "@apollo/client";

export const GET_USER_INFO = gql`
  query getUserInfo {
    getUserInfo {
      id
      name
      first_name
      last_name
    }
  }
`;

export const REMOVE_USER_TOKEN = gql`
  mutation removeUserToken {
    invalidateUserToken
  }
`;
