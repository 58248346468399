import { useEffect, useState } from "react";
import { Divider } from "antd";
import { FormItem, TreeSelect } from "formik-antd";
import { gql, useQuery } from "@apollo/client";

import { Container } from "./styled";

const { TreeNode } = TreeSelect;

const GET_ALL_CUSTOMER_USERS = gql`
  query getAllCustomerUsers {
    getCustomerUsers {
      customerList {
        id
        guest_id
        title
        first_name
        last_name
        suffix
        mobile_number
        country_code
        gender
        email
        nationality
        birth_date
        country
        address
        gorewards_loyalty_card_number
        anniversary
        preferences
        id_type
        id_url
        offers
        third_party_products
        app_communications
      }
    }
  }
`;

const CategoriesContainer = ({ brandsData, formikBag, recipientCategories }) => {
  const { setFieldValue, values } = formikBag;
  const { hotel_booking } = values.categories;
  const [hotelsData, setHotelsData] = useState<any[]>([]);
  const [customerEmails, setCustomerEmails] = useState<any[]>([]);
  const { data } = useQuery(GET_ALL_CUSTOMER_USERS);

  useEffect(() => {
    let newData: any[] = [];
    brandsData.forEach((item) => {
      const parsedHotels = item?.hotels.map((hotel) => {
        return hotel.name;
      });
      newData = [...newData, ...parsedHotels];
    });
    setHotelsData(newData);
  }, []);

  useEffect(() => {
    if (data) {
      const emails = data.getCustomerUsers.customerList.map((user) => user.email);
      setCustomerEmails(emails);
    }
  }, [data]);

  const handleHotelBooking = (value) => {
    if (!value.includes("Brand List")) {
      setFieldValue("categories.brand_list", []);
    }
    if (!value.includes("Hotel List")) {
      setFieldValue("categories.hotel_list", []);
    }
  };

  const hotelBookingSelect = recipientCategories.includes("By Hotel Booking") ? (
    <FormItem
      label="By Hotel Booking"
      name="categories.hotel_booking"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <TreeSelect
        treeCheckable={true}
        name="categories.hotel_booking"
        style={{ width: "100%" }}
        placeholder="Select recipients"
        onChange={handleHotelBooking}
      >
        <TreeNode value="Completed" title="Completed" />
        <TreeNode value="Upcoming" title="Upcoming">
          <TreeNode value="Brand List" title="Brand List" />
          <TreeNode value="Hotel List" title="Hotel List" />
        </TreeNode>
      </TreeSelect>
    </FormItem>
  ) : null;

  const brandSelect =
    recipientCategories.includes("By Hotel Booking") && hotel_booking.includes("Brand List") ? (
      <FormItem
        label="Brand List"
        name="categories.brand_list"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <TreeSelect
          treeCheckable={true}
          name="categories.brand_list"
          style={{ width: "100%" }}
          placeholder="Select brands"
        >
          {brandsData?.map((brand) => (
            <TreeNode value={brand.name} title={brand.name} />
          ))}
        </TreeSelect>
      </FormItem>
    ) : null;

  const hotelSelect =
    recipientCategories.includes("By Hotel Booking") && hotel_booking.includes("Hotel List") ? (
      <FormItem
        label="Hotel List"
        name="categories.hotel_list"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <TreeSelect
          treeCheckable={true}
          name="categories.hotel_list"
          style={{ width: "100%" }}
          placeholder="Select hotels"
        >
          {hotelsData?.map((hotel) => (
            <TreeNode value={hotel} title={hotel} />
          ))}
        </TreeSelect>
      </FormItem>
    ) : null;

  const statusSelect = recipientCategories.includes("By Status") ? (
    <>
      {recipientCategories.includes("By Hotel Booking") ? <Divider dashed={true} /> : null}
      <FormItem
        label="Status"
        name="categories.status"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <TreeSelect
          treeCheckable={true}
          name="categories.status"
          style={{ width: "100%" }}
          placeholder="Select status"
        >
          <TreeNode value="active" title="Active" />
          <TreeNode value="inactive" title="Inactive" />
        </TreeSelect>
      </FormItem>
    </>
  ) : null;

  const birthdaySelect = recipientCategories.includes("By Birthday") ? (
    <FormItem
      label="By Birthday"
      name="categories.birthday"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <TreeSelect
        treeCheckable={true}
        name="categories.birthday"
        style={{ width: "100%" }}
        placeholder="Select birthday"
        onChange={handleHotelBooking}
      >
        <TreeNode value="Month" title="Month" />
        <TreeNode value="Actual day" title="Actual day" />
      </TreeSelect>
    </FormItem>
  ) : null;

  const anniversarySelect = recipientCategories.includes("By Anniversary") ? (
    <FormItem
      label="By Anniversary"
      name="categories.anniversary"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <TreeSelect
        treeCheckable={true}
        name="categories.anniversary"
        style={{ width: "100%" }}
        placeholder="Select Anniversary"
        onChange={handleHotelBooking}
      >
        <TreeNode value="Month" title="Month" />
        <TreeNode value="Actual day" title="Actual day" />
      </TreeSelect>
    </FormItem>
  ) : null;

  const locationSelect = recipientCategories.includes("By Location") ? (
    <FormItem
      label="By Location"
      name="categories.location"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <TreeSelect
        treeCheckable={true}
        name="categories.location"
        style={{ width: "100%" }}
        placeholder="Select Location"
        onChange={handleHotelBooking}
      >
        <TreeNode value="Country" title="Country" />
      </TreeSelect>
    </FormItem>
  ) : null;

  const emailSelect = recipientCategories.includes("By Email") ? (
    <>
      {recipientCategories.includes("By Hotel Booking") ||
      recipientCategories.includes("By Status") ? (
        <Divider dashed={true} />
      ) : null}
      <FormItem
        label="Email"
        name="categories.email"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <TreeSelect
          treeCheckable={true}
          name="categories.email"
          style={{ width: "100%" }}
          placeholder="Select email"
        >
          {customerEmails?.map((email) => (
            <TreeNode value={email} title={email} />
          ))}
        </TreeSelect>
      </FormItem>
    </>
  ) : null;

  const genderSelect = recipientCategories.includes("By Gender") ? (
    <FormItem
      label="By Gender"
      name="categories.gender"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <TreeSelect
        treeCheckable={true}
        name="categories.gender"
        style={{ width: "100%" }}
        placeholder="Select Gender"
        onChange={handleHotelBooking}
      >
        <TreeNode value="Male" title="Male" />
        <TreeNode value="Female" title="Female" />
        <TreeNode value="Prefer not to say" title="Prefer not to say" />
      </TreeSelect>
    </FormItem>
  ) : null;

  const platformSelect = recipientCategories.includes("By Platform") ? (
    <FormItem
      label="By Platform"
      name="categories.platform"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <TreeSelect
        treeCheckable={true}
        name="categories.platform"
        style={{ width: "100%" }}
        placeholder="Select Platform"
        onChange={handleHotelBooking}
      >
        <TreeNode value="Website" title="Website" />
        <TreeNode value="Mobile App" title="Mobile App" />
      </TreeSelect>
    </FormItem>
  ) : null;

  return (
    <Container>
      {hotelBookingSelect}
      {brandSelect}
      {hotelSelect}
      {statusSelect}
      {birthdaySelect}
      {anniversarySelect}
      {locationSelect}
      {emailSelect}
      {genderSelect}
      {platformSelect}
    </Container>
  );
};

export default CategoriesContainer;
