import React, { useState, useEffect, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";

import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ReactExport from "react-data-export";
import KebabIconImg from "../../../../../assets/images/icons/ic_more_kebab.svg";
import { EyeIcon } from "../../../../../utilities/icons";
import { dataTypes, userKeys } from "./constants";

import { DownloadOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Modal,
  Radio,
  Space,
  Avatar,
  Table,
  Menu,
  Pagination,
  Dropdown,
  DatePicker,
} from "antd";
import { DownloadButton } from "./style";
import { useLazyQuery, useQuery } from "@apollo/client";

import { GET_ALL_USERS, GET_CUSTOMER_USERS, SEARCH_USER } from "../../../graphql";

import {
  CustomerViewPageWrap,
  ContentContainer,
  SectionWrap,
  Sectiontitle,
  Status,
  Graytitle,
  BlueTitle,
  BlackTitle,
  LightBlueTitle,
  DownloadPDFErrorMessage,
} from "./style";
import { IconKey, IconKeyTextCont, MenuContainer } from "@/components/CustomDashboardList/styled";
import { Container } from "@/components/ContentContainer/styled";
import { StyledButton, StyledGroup, StyledSearch } from "@/components/CustomDashboard/styled";

const { SubMenu } = Menu;

const CustomerUserList: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  let match = useRouteMatch();
  const history = useHistory();

  const [customerList, setCustomerList] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [dataLength, setDataLength] = useState(0);
  const [docFileType, SetdocFileType] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataTypeLabel, setDataTypeLabel] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string[]>(dataTypes.map((type) => type.value));
  const [storeSelectedData, setStoreSelectedData] = useState<any>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [allGoods, setAllGoods] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [downloadPDFErrorMessage, setDownloadPDFErrorMessage] = useState("");

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const {
    loading: customerListLoading,
    refetch,
    data: customerData,
  } = useQuery(GET_ALL_USERS, {
    variables: {
      pageSize: 10,
      page: 1,
    },
  });

  const [getUser, { data }] = useLazyQuery(GET_CUSTOMER_USERS);
  const [getResult, { data: searchdata }] = useLazyQuery(SEARCH_USER);

  useEffect(() => {
    if (data) {
      const {
        getCustomerUsers: { customerList: users },
      } = data;

      const mappedData = users.map((el: any) => {
        return { ...el, ...userKeys };
      });
      setCustomerList(mappedData);
      setSelectedData(mappedData);
    }
  }, [data]);

  const onSearchInput = (e) => {
    getResult({
      variables: {
        search: e.toLowerCase(),
      },
    });
  };

  useEffect(() => {
    if (searchdata) {
      setSelectedData(searchdata.getCustomerUsersByFilter.customerList);
    }
  }, [searchdata]);

  const onChangeSearchInput = (e) => {
    const { value } = e.target;
    setSearchInput(value);
    if (value === "") {
      setSelectedData(
        customerList.filter((item) => {
          return selectedType.includes(item.__typename);
        })
      );
    } else {
      onSearchInput(value);
    }
  };

  const onChangeDataTypes = (checktype) => {
    setSelectedType([...checktype]);
  };

  useEffect(() => {
    setStoreSelectedData(customerList);
  }, [customerList]);

  useEffect(() => {
    if (selectedType.length === dataTypes.length) {
      setSelectedData(customerList);
    }

    const placeholder = dataTypes
      .filter((item) => selectedType.includes(item.value))
      .map((item) => item.label)
      .join(", ");
    setDataTypeLabel(placeholder);
    const filteredData = customerList.filter((item) => {
      return selectedType.includes(item.__typename);
    });
    setSelectedData(filteredData);
    setStoreSelectedData(filteredData);
    setSearchInput("");
  }, [selectedType]);

  useEffect(() => {
    if (customerData) {
      refetch();
      const {
        getCustomerUsers: { customerList: users, listLength: usersCount },
      } = customerData;

      const mappedData = users.map((el: any) => {
        return { ...el, ...userKeys };
      });
      setDataLength(usersCount);
      setCustomerList(mappedData);
      setSelectedData(mappedData);
    }
  }, [customerData, refetch]);

  const onChangeFileType = (e) => {
    SetdocFileType(e.target.value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setDownloadLoading(true);
    if (docFileType === "PDF") {
        try{
          const response = await fetch(
            `${process.env.REACT_APP_CONTENT_SERVICE}/users/get-customer-list-pdf-v2`,
            {
              method: "POST",
              headers: {
                authorization: `Bearer ${sessionStorage.getItem("act")}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                startDate: startDate,
                endDate: endDate,
                customerType: "registered",
              }),
            }
          );

          // Early return if response is not ok
          if(!response.ok){
            // Catch error 400: Bad Request and display error message "Choose a shorter date range"
            if(response.status === 400){
              const error = await response.json();
              setDownloadPDFErrorMessage(error.message); 
            }
            setDownloadLoading(false);
          
            return;
          }

          // Clear error message if there is any
          setDownloadPDFErrorMessage("");

          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          setDownloadLoading(false);
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "document.pdf");
      
          document.body.appendChild(link);
          link.click();
      
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }catch(error){
          console.error("Error:", error);
        }
    } else {
      try {
        fetch(`${process.env.REACT_APP_CONTENT_SERVICE}/users/get-customer-list-xlsx`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${sessionStorage.getItem("act")}`,
          },
          body: JSON.stringify({
            includeAnonymous: true,
          }),
        })
          .then((response) => response.blob())
          .then((blob) => {
            setDownloadLoading(false);

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "filename.xlsx");
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  useEffect(() => {
    if (startDate && endDate) {
      setDisabledBtn(false);
      setAllGoods(true);
      console.log(startDate, endDate);
    }
  }, [startDate, endDate]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setDisabledBtn(false);
    SetdocFileType("");
  };

  const handlePrintPDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Customer_Users.pdf",
  });

  const handleSelectedRow = (id): void => {
    if (!id.includes("auth")) {
      return;
    }
    history.push(`${match.path}/view-user/${encodeURI(id)}`, { pageId: id });
  };

  const columns = [
    {
      title: "Guest ID",
      dataIndex: "guest_id",
      key: "guest_id",
      render: (_, record) => {
        return <BlackTitle>{record.guest_id ? record.guest_id : record.id}</BlackTitle>;
      },
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "name",
      render: (_, { first_name, last_name, suffix, name }) => {
        return <BlackTitle>{name ? name : `${first_name} ${last_name} ${suffix}`}</BlackTitle>;
      },
      width: "10%",
    },
    {
      title: "Platform",
      key: "platform",
      render: (_, record) => {
        return <LightBlueTitle>{record.platform ? record.platform : "Mobile"}</LightBlueTitle>;
      },
      width: "10%",
    },
    {
      title: "Last Logged in",
      key: "logged",
      render: (_, record) => {
        return (
          <div>
            <span>
              {record.last_logged_in
                ? moment(record.last_logged_in).format("YYYY-MM-DD HH:mm:ss")
                : moment(record.updated_at).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        );
      },
      width: "10%",
    },
    {
      title: "Last Edited by",
      dataIndex: "lastEditedBy",
      key: "edited",
      render: (_, record) => {
        return <LightBlueTitle>{record.lastEditedBy || "N/A"}</LightBlueTitle>;
      },
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return <BlackTitle> {record.status || "Active"}</BlackTitle>;
      },
      width: "10%",
    },
    {
      title: "Action",
      key: "Action",
      width: "10%",
      render: (_, record) => {
        if (!record.id.includes("auth")) {
          return;
        }
        return (
          <MenuContainer>
            <Menu selectable={false} className="kebab-menu">
              <SubMenu icon={<img src={KebabIconImg} alt="Kebab Icon" />}>
                <Menu.Item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: 35,
                    lineHeight: "unset",
                    margin: "0.5rem 0 0 0",
                    fontSize: 14,
                  }}
                  key="view"
                >
                  <IconKeyTextCont>
                    <IconKey>
                      <EyeIcon />
                    </IconKey>{" "}
                    View
                  </IconKeyTextCont>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </MenuContainer>
        );
      },
    },
  ];

  const sampleMenu = (
    <Menu>
      <Menu.Item key="data types" disabled>
        <StyledGroup
          options={dataTypes}
          defaultValue={dataTypes.map((type) => type.value)}
          onChange={onChangeDataTypes}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Modal
        title="Please choose the file type to download"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: disabledBtn }}
        afterClose={() => SetdocFileType("")}
      >
        <Radio.Group value={docFileType} onChange={onChangeFileType}>
          <Space direction="vertical">
            <Row>
              <Col>
                <Radio
                  value="PDF"
                  name="pdf"
                  onClick={() => {
                    SetdocFileType("PDF");
                    setDisabledBtn(true);
                  }}
                >
                  As PDF
                </Radio>
              </Col>
              {disabledBtn || allGoods ? (
                <Row gutter={10}>
                  <DatePicker
                    placeholder="Start ate"
                    style={{ marginRight: 10 }}
                    onChange={(val) => setStartDate(val?.format("YYYY-MM-DD")!)}
                  />
                  <DatePicker
                    placeholder="End date"
                    style={{ marginRight: 10 }}
                    onChange={(val) => setEndDate(val?.format("YYYY-MM-DD")!)}
                  />
                </Row>
              ) : null}
            </Row>

            <Radio
              value="EXCEL"
              name="excel"
              onClick={() => {
                setDisabledBtn(false);
                SetdocFileType("EXCEL");
              }}
            >
              As Excel
            </Radio>
          </Space>
        </Radio.Group>
        <Space direction="vertical">
          <DownloadOutlined />
          <DownloadOutlined />
        </Space>
        {downloadPDFErrorMessage && (
          <DownloadPDFErrorMessage>{downloadPDFErrorMessage}</DownloadPDFErrorMessage>
      )}
      </Modal>
      <Container>
        <Row justify="space-between" style={{ marginBottom: "2rem", paddingTop: "1rem" }}>
          <Col span={7}>
            <StyledSearch
              placeholder={"Search Customer"}
              enterButton
              allowClear
              onSearch={onSearchInput}
              onChange={onChangeSearchInput}
              value={searchInput}
            />
          </Col>

          <Col span={8} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <span style={{ marginRight: "1rem", width: "7rem", color: "black" }}>
              Select Data Type
            </span>
            <Dropdown overlay={sampleMenu}>
              <StyledButton>
                <span className="label">{dataTypeLabel}</span> <DownOutlined />
              </StyledButton>
            </Dropdown>
          </Col>
        </Row>
        <Table
          dataSource={
            searchInput
              ? selectedData
              : customerList.filter(({ __typename }) => selectedType.includes(__typename))
          }
          columns={columns}
          pagination={false}
          onRow={(record: any, rowIndex) => {
            return {
              onClick: (event) => handleSelectedRow(record.id),
            };
          }}
        />
        <div style={{ display: "flex", justifyContent: "end", padding: "10px 0" }}>
          <Pagination
            defaultCurrent={1}
            total={dataLength}
            onChange={async (page, pageSize) => {
              getUser({
                variables: {
                  page,
                  pageSize: pageSize ? pageSize : 10,
                },
              });
            }}
          />
        </div>
      </Container>

      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <CustomerViewPageWrap>
            <Row style={{ marginBottom: "2rem" }}></Row>
            {selectedData.map((cus, index) => {
              return (
                <SectionWrap>
                  <div style={{ display: "none" }}>
                    <ExcelFile
                      element={<button className="dloadExcel">Download Data</button>}
                      filename={"Customer Users"}
                    >
                      <ExcelSheet data={selectedData} name="Customer Users">
                        <ExcelColumn
                          label="Guest ID"
                          bold={true}
                          value={(col) => (col.guest_id ? col.guest_id : col.id)}
                        />
                        <ExcelColumn
                          label="First Name"
                          value={(col) => (col.first_name ? col.first_name : col.name)}
                        />
                        <ExcelColumn label="Last Name" value="last_name" />
                        <ExcelColumn label="Suffix" value="suffix" />
                        <ExcelColumn label="Gender" value="gender" />
                        <ExcelColumn label="Mobile Number" value="mobile_number" />
                        <ExcelColumn label="Email" value="email" />
                        <ExcelColumn
                          label="Date of Birth"
                          value={(col) => moment(parseInt(col.birth_date)).format("YYYY-MM-DD")}
                        />
                        <ExcelColumn
                          label="Nationality"
                          value={(col) =>
                            col.nationality ? JSON.parse(col.nationality).name : "N/A"
                          }
                        />
                        <ExcelColumn
                          label="Country"
                          value={(col) => (col.country ? JSON.parse(col.country)?.name : "N/A")}
                        />
                        <ExcelColumn label="Address" value="address" />
                        <ExcelColumn
                          label="Go Rewards Card Number"
                          value="gorewards_loyalty_card_numberes"
                        />
                        <ExcelColumn label="ID Type" value="id_type" />
                        <ExcelColumn label="ID URL" value="id_url" />
                        <ExcelColumn label="Created at" value="created_at" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>

                  <ContentContainer style={{ margin: "0", padding: "0 45px" }}>
                    <Sectiontitle style={{ marginBottom: "0" }}>Personal Details</Sectiontitle>
                  </ContentContainer>

                  <ContentContainer>
                    <Col>
                      <Row>
                        {/*  */}
                        <Col>
                          <Avatar
                            size={100}
                            style={{
                              backgroundColor: "rgb(29,43,66)",
                              fontSize: "40px",
                              marginRight: "25px",
                            }}
                          >
                            {cus.first_name?.substring(0, 1) || "N/A"}
                          </Avatar>
                        </Col>
                        <Col style={{ marginTop: "1.2rem", marginRight: "auto" }}>
                          <Sectiontitle style={{ marginBottom: "0" }}>
                            {cus.first_name ? cus.first_name : cus.name} {cus.last_name}
                          </Sectiontitle>
                          <Graytitle>Guest ID: {cus.guest_id ? cus.guest_id : cus.id}</Graytitle>
                        </Col>
                        <Col style={{ paddingTop: "1rem", fontSize: "14px" }}>
                          <Status>ACTIVE </Status>
                        </Col>
                      </Row>
                    </Col>

                    <Row style={{ paddingTop: "2.5rem" }}>
                      <Col span={5}>
                        <Graytitle>Mobile Number</Graytitle>
                      </Col>
                      <Col>
                        <BlueTitle>
                          {cus.country_code}
                          {cus.birth_date}
                        </BlueTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Email Address</Graytitle>
                      </Col>
                      <Col>
                        <BlueTitle>{cus.email || "N/A"}</BlueTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Date of Birth</Graytitle>
                      </Col>
                      <Col>
                        <BlackTitle>
                          {moment(parseInt(cus.birth_date)).format("YYYY-MM-DD") || "N/A"}
                        </BlackTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Nationality</Graytitle>
                      </Col>
                      <Col>
                        <BlackTitle>
                          {cus.nationality ? JSON.parse(cus.nationality).name : "N/A"}
                        </BlackTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Gender</Graytitle>
                      </Col>
                      <Col>
                        <BlackTitle>{cus.gender || "N/A"}</BlackTitle>
                      </Col>
                    </Row>
                  </ContentContainer>
                  <ContentContainer style={{ paddingBottom: "10px" }}>
                    <Sectiontitle style={{ marginBottom: "1.5rem" }}>Other Details</Sectiontitle>
                    <Row>
                      <Col span={9}>
                        <Row>
                          <Col span={8}>
                            <Graytitle>Country</Graytitle>
                          </Col>
                          <Col span={8}>
                            <BlackTitle>
                              {cus.country ? JSON.parse(cus.country)?.name : "N/A"}
                            </BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={8}>
                            <Graytitle>Address</Graytitle>
                          </Col>
                          <Col span={10}>
                            <BlackTitle>{cus.address || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={9}>
                        <Row>
                          <Col span={12}>
                            <Graytitle>Anniversary Date</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.anniversary || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={9}>
                            <Graytitle>Preferences</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.preferences || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={11}>
                            <Graytitle>Go Rewards No.</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.gorewards_loyalty_card_number || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={6}>
                        <Row>
                          <Col span={11}>
                            <Graytitle>Type of ID</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.id_type || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={11}>
                            <Graytitle>Uploaded ID</Graytitle>
                          </Col>
                          <Col span={7}>
                            <BlackTitle>{cus.id_url || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ContentContainer>

                  <ContentContainer>
                    <Sectiontitle style={{ marginBottom: "1.5rem" }}>Logs</Sectiontitle>
                    <Row>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Date/Time Created</BlueTitle>
                            <BlackTitle>
                              {moment(cus.created_at).format("YYYY-MM-DD") || "N/A"}
                            </BlackTitle>
                            <LightBlueTitle> </LightBlueTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Last Date/Time Edited</BlueTitle>
                            <BlackTitle>
                              {" "}
                              {moment(cus.created_at).format("YYYY-MM-DD") || "N/A"}{" "}
                            </BlackTitle>
                            <LightBlueTitle> by {cus.first_name} </LightBlueTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row>
                          <Col>
                            <BlueTitle>Last Logged in</BlueTitle>
                            <BlackTitle>N/A </BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row>
                          <Col>
                            <BlueTitle>Last Page Visited</BlueTitle>
                            <BlackTitle> N/A</BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: "30px", marginTop: "50px" }}>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Pages Frequently Visited</BlueTitle>
                            <BlackTitle style={{ maxWidth: "260px" }}> N/A</BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Notification Subscription</BlueTitle>
                            <BlackTitle style={{ maxWidth: "260px" }}>
                              {" "}
                              {cus.promotions == "ENABLED" ? "News Letter" : "N/A"}{" "}
                            </BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row>
                          <Col>
                            <BlueTitle>Platform</BlueTitle>
                            <BlackTitle> MOBILE </BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ContentContainer>
                </SectionWrap>
              );
            })}
          </CustomerViewPageWrap>
        </div>
      </div>
      <Row justify="end">
        <Col>
          <DownloadButton onClick={showModal}>DOWNLOAD FILE</DownloadButton>
        </Col>
      </Row>
    </>
  );
};

export default CustomerUserList;
