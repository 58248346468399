import { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";
import { upperCase } from "lodash";

import CustomDashboardList from "@/components/CustomDashboardList2";
import CustomModal from "@/components/CustomModal";

import { activeColumns } from "./constants";
import { BrandsI } from "../../../../../models/RHRInterface";
import { GET_HERO_BANNERS, MODIFY_STATUS, UPDATE_HERO_BANNER_ORDER } from "../../../graphql";
import { STATUS } from "../../../../../utilities/enums";

const RhrHeroBannerList = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [showArrangeModal, setShowArrangeModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState("");
  const [statusStr, setStatusStr] = useState("");
  const [brandId, setBrandId] = useState("");
  const [brandNameModal, setBrandNameModal] = useState("");
  const [okTxt, setOkTxt] = useState("OK");
  const [startIndex, setStartIndex] = useState<number>(0);
  const [targetIndex, setTargetIndex] = useState<number>(0);
  const [heroBanners, setHeroBanners] = useState<BrandsI[]>([]);
  const [modifyStatus] = useMutation(MODIFY_STATUS);
  const [modifyOrder] = useMutation(UPDATE_HERO_BANNER_ORDER);

  const {
    loading: rhrLoading,
    refetch,
    data: heroBannerData,
  } = useQuery(GET_HERO_BANNERS, {
    variables: {
      search: "",
    },
  });

  useEffect(() => {
    if (heroBannerData) {
      refetch();
      const list = [...heroBannerData.getHeroBanners].sort((a, b): any => {
        return a.order < b.order;
      });

      setHeroBanners(list);
      console.log("updated successfully");
    }
  }, [heroBannerData, refetch]);

  const fetchData = (callback) => {
    if (heroBannerData) {
      callback(heroBannerData.getHeroBanners.slice(heroBanners.length).slice(0, 10));
    }
  };

  const handleAddBtn = () => {
    history.push(`${match.path}/add-banner-item`);
  };

  const handleSelectedRow = (id, slug, brandName) => {
    history.push(`${match.path}/view-banner-item/${id}`, { pageId: id });
  };

  const constructVariables = (hero_banner_id: string, status: STATUS) => {
    return {
      variables: {
        hero_banner_id: hero_banner_id,
        status: upperCase(status),
      },
    };
  };

  const updateStatus = async (id: string, status: STATUS) => {
    try {
      const { data: res } = await modifyStatus(constructVariables(id, status));

      if (res.updateHeroBannerStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (id, statusStr, brandNameModal) => {
    if (statusStr === "published") {
      await updateStatus(id, STATUS.PUBLISHED);
      setBodyMessageSuccess(`Banner item was successfully published.`);
    }

    if (statusStr === "archived") {
      await updateStatus(id, STATUS.ARCHIVED);
      setBodyMessageSuccess(`This banner item was successfuly archived.`);
    }
    if (statusStr === "deleted") {
      await updateStatus(id, STATUS.DELETED);
      setBodyMessageSuccess(`This banner item was successfuly deleted.`);
    }
    setShowModal(false);

    setShowModalSuccess(true);
  };

  const handlePublish = (id, brandName) => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By Publishing this content, all information will be visible in the website once deployed."
    );
    setStatusStr("published");
    setBrandNameModal(brandName);
    setBrandId(id);
    setOkTxt("Publish");
    setIsPublish(true);
  };

  const handleArchived = (id, brandName) => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage("By archiving this brand, it will not be visible in the website when deployed.");
    setStatusStr("archived");
    setBrandNameModal(brandName);
    setBrandId(id);
    setOkTxt("Archive");
    setIsPublish(false);
  };

  const handleRearrange = async (start, target, id): Promise<void> => {
    const reorderedList = heroBanners
      .filter((item) => {
        if (start < target) {
          return item.order > start && item.order <= target;
        } else {
          return item.order < start && item.order >= target;
        }
      })
      .map((item, index) => {
        const newOrder = start < target ? item.order - 1 : item.order + 1;

        return {
          id: item.id,
          order: newOrder,
        };
      });

    try {
      const { data: res } = await modifyOrder({
        variables: {
          updateHeroBannerOrderInput: {
            details: [
              ...reorderedList,
              {
                id: id,
                order: target,
              },
            ],
          },
        },
      });

      if (res.updateHeroBannerOrder) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
    setShowArrangeModal(false);
  };

  const handleDelete = (id, brandName) => {
    setShowModal(true);
    setTitleMessage("Delete Brand?");
    setBodyMessage(`Are you sure you want to delete "${brandName}?"`);
    setStatusStr("deleted");
    setBrandNameModal(brandName);
    setBrandId(id);
    setOkTxt("Delete");
    setIsPublish(false);
  };

  const handleKebabMenu = (menuItem: string, id: string, slug: string, brandName: string) => {
    if (menuItem === "edit") {
      history.push(`${match.path}/edit-banner-content/${id}`, { pageId: id });
    }
    if (menuItem === "view") {
      history.push(`${match.path}/view-banner-item/${id}`, { pageId: id });
    }
    if (menuItem === "publish") {
      handlePublish(id, brandName);
    }
    if (menuItem === "delete") {
      handleDelete(id, brandName);
    }
    if (menuItem === "archive") {
      handleArchived(id, brandName);
    }
  };

  const handleInfiniteOnLoad = () => {
    let dataList = heroBannerData?.getHeroBanners;

    fetchData((res) => {
      dataList = heroBanners.concat(res);
      setTimeout(() => {
        setHeroBanners(dataList);
      }, 600);
    });
  };

  const handleDrag = (result): void => {
    if (!result.destination) {
      console.log("destination is null: " + result.destination);
      return;
    }

    if (result.source.index !== result.destination.index) {
      setShowArrangeModal(true);
      setOkTxt("Ok");
      setBodyMessage(`Are you sure you want to reorder hero banner?`);
      setStartIndex(result.source.index);
      setTargetIndex(result.destination.index);
      setBrandId(result.draggableId);
    }
  };

  return (
    <>
      <CustomDashboardList
        loading={rhrLoading}
        headerColumns={activeColumns}
        data={heroBanners}
        hasMore={true}
        handleAddBtn={handleAddBtn}
        handleInfiniteOnLoad={handleInfiniteOnLoad}
        handleMenu={handleKebabMenu}
        onDragEnd={handleDrag}
        addLabelText=""
        handleSelectedRow={handleSelectedRow}
      />

      {/* Confirmation modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={() => checkStatusStrOkModal(brandId, statusStr, brandNameModal)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={isPublish ? "Great!" : "OK"}
      />

      {/* Arrange Confirmation Modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showArrangeModal}
        onOk={() => handleRearrange(startIndex, targetIndex, brandId)}
        onCancel={() => setShowArrangeModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />
    </>
  );
};

export default RhrHeroBannerList;
