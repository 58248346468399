import moment from "moment";

export const activeColumns = [
  {
    title: "Guest ID",
    dataIndex: "guestId",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Platform",
    dataIndex: "platform",
  },
  {
    title: "Last Logged in",
    dataIndex: "lastLoggedIn",
  },
  {
    title: "Last Edited by",
    dataIndex: "lastEditedBy",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

export const dataTypes = [
  {
    label: "Mobile App User",
    value: "CustomerUser",
  },
  {
    label: "Anonymous Guest",
    value: "AnonymousGuest",
  },
];

export const actions = [
  {
    name: "View",
    checkStatus: null,
  },
];

export const guestKeys = {
  guest_id: null,
  title: null,
  first_name: null,
  last_name: null,
  suffix: null,
  country_code: null,
  mobile_number: null,
  gender: null,
  email: null,
  birth_date: null,
  nationality: null,
  country: null,
  address: null,
  gorewards_loyalty_card_number: null,
  anniversary: null,
  preferences: null,
  id_type: null,
  id_url: null,
  offers: null,
  updated_at: null,
};

export const userKeys = {
  name: null,
  platform: null,
  last_logged_in: null,
};

export const dummy = {
  data: {
    getCustomerUsers: {
      listLength: 33,
      customerList: [
        {
          id: "auth0|62318ef85aa10200756c2a8e",
          guest_id: "0000000321",
          title: "None",
          first_name: "Jm",
          last_name: "Baldonado",
          suffix: "",
          country_code: "+63",
          mobile_number: "9123456789",
          gender: "OTHERS",
          email: "jm.baldonado@whitecloak.com",
          birth_date: "1010188800000",
          nationality: '{"name":"Philippines","id":430}',
          country: '{"name":"Cyprus","id":307}',
          address: "",
          gorewards_loyalty_card_number: "",
          anniversary: "",
          preferences: "edit",
          id_type: "OTHERS",
          id_url: "",
          offers: "ENABLED",
          created_at: "2022-03-16T07:17:12.532Z",
          updated_at: "2023-09-13T09:22:38.682Z",
          __typename: "CustomerUser",
        },
        {
          id: "auth0|64d47bdf0b5e418c2b51e59b",
          guest_id: "RLC230000000554",
          title: "Mr",
          first_name: "John Mark",
          last_name: "Canja",
          suffix: "",
          country_code: "+63",
          mobile_number: "9206235030",
          gender: "MALE",
          email: "johncanjatestemail@gmail.com",
          birth_date: "551232000000",
          nationality: '{"name":"Philippines","id":430}',
          country: '{"name":"Philippines","id":430}',
          address: "test2",
          gorewards_loyalty_card_number: "56789",
          anniversary: "2023-08-10",
          preferences: "test edit Aug 11",
          id_type: "OTHERS",
          id_url: "",
          offers: "DISABLED",
          created_at: "2023-08-10T05:55:43.936Z",
          updated_at: "2023-08-11T03:55:22.045Z",
          __typename: "CustomerUser",
        },
        {
          id: "auth0|627c6e589f3833006fd44d9c",
          guest_id: "0000000532",
          title: "None",
          first_name: "christian kin",
          last_name: "del MUNDO",
          suffix: "",
          country_code: "+63",
          mobile_number: "9997321231",
          gender: "MALE",
          email: "christian.lascuna+2@gmail.com",
          birth_date: "903398400000",
          nationality: '{"name":"Philippines","id":430}',
          country: "{}",
          address: "",
          gorewards_loyalty_card_number: null,
          anniversary: "",
          preferences: "",
          id_type: null,
          id_url: null,
          offers: "DISABLED",
          created_at: "2022-05-12T02:18:01.142Z",
          updated_at: "2022-05-12T02:18:01.142Z",
          __typename: "CustomerUser",
        },
        {
          id: "auth0|620b5bf26b0f4b0070d31622",
          guest_id: "0000000349",
          title: "None",
          first_name: "Juan",
          last_name: "Dela Cruz",
          suffix: "iii",
          country_code: "+63",
          mobile_number: "9991234567",
          gender: "OTHERS",
          email: "juandelacruz@yahoo.com",
          birth_date: "585619200000",
          nationality: '{"name":"Philippines","id":430}',
          country: "null",
          address: "1",
          gorewards_loyalty_card_number: "1234567890",
          anniversary: "2022-03-27",
          preferences: "",
          id_type: "COMPANY",
          id_url:
            "/download-cmd-doc/accounts/566/properties/1072/upload-docs/565babf5-63df-411a-9d05-8c9478406f62/",
          offers: "DISABLED",
          created_at: "2022-02-15T07:53:25.303Z",
          updated_at: "2022-05-05T08:32:52.568Z",
          __typename: "CustomerUser",
        },
        {
          id: "auth0|649a96292ba98de6c0b77130",
          guest_id: "0000000349",
          title: "Mr",
          first_name: "Juan",
          last_name: "dela Cruz",
          suffix: null,
          country_code: "+63",
          mobile_number: "9876543212",
          gender: "MALE",
          email: "juandelacruz@yahoo.com",
          birth_date: "946684800000",
          nationality: "{}",
          country: "{}",
          address: null,
          gorewards_loyalty_card_number: null,
          anniversary: null,
          preferences: null,
          id_type: null,
          id_url: null,
          offers: "ENABLED",
          created_at: "2023-06-27T08:22:04.830Z",
          updated_at: "2023-06-27T08:22:04.830Z",
          __typename: "CustomerUser",
        },
      ],
      __typename: "CustomerPaginatedList",
    },
    getAnonymousGuests: {
      listLength: 33,
      customerList: [
        {
          id: "008b823d-a754-4db0-be16-9de77187c21a",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2023-09-13T09:19:32.736Z",
          created_at: "2023-09-13T09:19:32.736Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "02171be9-a068-48bf-94ff-db82890a9a80",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-09-13T08:53:20.189Z",
          created_at: "2022-09-13T08:53:20.189Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "06acceb5-8771-4e66-a291-7e7f04f2a27f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-10-17T07:23:40.436Z",
          created_at: "2022-10-17T07:23:40.436Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "0bd531cf-e75e-4ff0-b206-0fbfae389a29",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-11-18T03:09:48.024Z",
          created_at: "2022-11-18T02:44:55.361Z",
          __typename: "AnonymousGuest",
        },
        {
          id: "0dc2e0f9-c3f7-4168-9974-6bb0a5dbf10f",
          name: "Anonymous guest",
          platform: "mobile",
          last_logged_in: "2022-02-28T07:50:29.520Z",
          created_at: "2022-02-28T02:07:45.902Z",
          __typename: "AnonymousGuest",
        },
      ],
      __typename: "AnonCustomerPaginatedList",
    },
  },
};
