import { gql } from "@apollo/client";

export const GET_CMS_USERS = gql`
  query getUsers {
    getUsers(search: "") {
      id
      email
      name
      nickname
      access_role
      company
      picture
      status
      updated_at
      last_logged_in
    }
  }
`;

export const GET_CMS_USER = gql`
  query getUserById($id: String!) {
    getUserById(id: $id) {
      id
      email
      first_name
      last_name
      access_role
      contact_number
      company
      position
      status
      last_logged_in
      created_at
      created_by
      updated_at
      updated_by
    }
  }
`;

export const GET_CMS_EDIT_USER = gql`
  query getUserById($id: String!) {
    getUserById(id: $id) {
      id
      email
      first_name
      last_name
      access_role
      contact_number
      company
      position
    }
  }
`;

export const CREATE_CMS_USER = gql`
  mutation createUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
      email
    }
  }
`;

export const EDIT_CMS_USER = gql`
  mutation editUser($editUserInput: EditUserInput!) {
    editUser(editUserInput: $editUserInput) {
      id
      email
    }
  }
`;

export const UPDATE_CMS_USER_STATUS = gql`
  mutation updateUserStatus($updateUserStatusInput: UpdateUserStatusInput!) {
    updateUserStatus(updateUserStatusInput: $updateUserStatusInput) {
      id
      status
    }
  }
`;

export const GET_UPDATE_AND_ANNOUNCEMENT = gql`
  query getUpdateAndAnnouncement($id: String!) {
    getUpdateAndAnnouncementById(id: $id) {
      id
      title
      photo_url
      short_description
      redirect_link
      status
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
      order
    }
  }
`;

export const GET_COUNTRY_CODE_LIST = gql`
  query {
    countryCodes {
      id
      name
      flag
      code
      dial_code
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetCustomerUsers($pageSize: Float, $page: Float) {
    getCustomerUsers(pageSize: $pageSize, page: $page) {
      listLength
      customerList {
        id
        guest_id
        title
        first_name
        last_name
        suffix
        country_code
        mobile_number
        gender
        email
        birth_date
        nationality
        country
        address
        gorewards_loyalty_card_number
        anniversary
        preferences
        id_type
        id_url
        offers
        created_at
        updated_at
      }
    }
    getAnonymousGuests(pageSize: $pageSize, page: $page) {
      listLength
      customerList {
        id
        name
        platform
        last_logged_in
        created_at
      }
    }
  }
`;

export const GET_CUSTOMER_USERS = gql`
  query GetCustomerUsers($pageSize: Float, $page: Float) {
    getCustomerUsers(pageSize: $pageSize, page: $page) {
      listLength
      customerList {
        id
        guest_id
        title
        first_name
        last_name
        suffix
        country_code
        mobile_number
        gender
        email
        birth_date
        nationality
        country
        address
        gorewards_loyalty_card_number
        anniversary
        preferences
        id_type
        id_url
        offers
        created_at
        updated_at
      }
    }
  }
`;

export const GET_ANONYMOUS = gql`
  query GetAnonymousGuests($pageSize: Float, $page: Float) {
    getAnonymousGuests(pageSize: $pageSize, page: $page) {
      listLength
      customerList {
        id
        name
        platform
        last_logged_in
        created_at
      }
    }
  }
`;

export const GET_CUSTOMER_USER = gql`
  query getCustomerUserById($id: String!) {
    getCustomerUserById(id: $id) {
      id
      guest_id
      first_name
      last_name
      title
      suffix
      country_code
      mobile_number
      suffix
      gender
      email
      birth_date
      nationality
      country
      address
      gorewards_loyalty_card_number
      anniversary
      preferences
      promotions
      created_at
      updated_at
    }
  }
`;

export const GET_ROLES = gql`
  query getRoles {
    getRoles(search: "") {
      id
      name
      scope
      updated_at
      updated_by
      created_at
      created_by
      status
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation createRole($createRoleInput: CreateRoleInput!) {
    createRole(createRoleInput: $createRoleInput) {
      id
      name
      scope
      created_at
      created_by
    }
  }
`;

export const GET_ROLE = gql`
  query getRole($id: String!) {
    getRoleById(id: $id) {
      name
      scope
      status
      updated_at
      updated_by
      created_at
      created_by
    }
  }
`;

export const EDIT_ROLE = gql`
  mutation editRole($editRoleInput: EditRoleInput!) {
    editRole(editRoleInput: $editRoleInput) {
      id
      name
      scope
      updated_at
      updated_by
    }
  }
`;

export const UPDATE_ROLE_STATUS = gql`
  mutation updateRoleStatus($updateRoleStatus: UpdateRoleStatusInput!) {
    updateRoleStatus(updateRoleStatus: $updateRoleStatus) {
      id
      name
      scope
      created_by
      created_at
      updated_at
      updated_by
      is_deleted
      status
    }
  }
`;

export const SEARCH_USER = gql`
  query GetCustomerUsersByFilter($search: String!) {
    getCustomerUsersByFilter(
      Input: {
        searchString: $search
        app_communications: false
        booking: false
        in_stay: false
        promotions: false
        third_party_products: false
      }
    ) {
      listLength
      customerList {
        id
        guest_id
        title
        first_name
        last_name
        suffix
        country_code
        mobile_number
        gender
        email
        birth_date
        nationality
        country
        address
        gorewards_loyalty_card_number
        anniversary
        preferences
        id_type
        id_url
        offers
        third_party_products
        app_communications
        booking
        promotions
        in_stay
        created_at
        updated_at
      }
    }
  }
`;
