import React, { useState, useEffect } from "react";
import { Row, Col, Avatar, Spin } from "antd";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import CustomButton from "@/components/CustomButton";
import { ArrowLeftOutlined } from "@ant-design/icons";

import moment from "moment";

import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_USER } from "../../../graphql";

// import ContentContainer from "@/components/ContentContainer";
import {
  CustomerViewPageWrap,
  ContentContainer,
  SectionWrap,
  Sectiontitle,
  Status,
  BorderTop,
  Graytitle,
  BlueTitle,
  BlackTitle,
  LightBlueTitle,
} from "./stye";

const CustomerUserView = (): JSX.Element => {
  const { pageId }: { pageId: string } = useParams();
  const history = useHistory();
  let match = useRouteMatch();

  const [customerData, setCustomerData] = useState<any>();

  const { loading, refetch, data } = useQuery(GET_CUSTOMER_USER, {
    skip: !decodeURI(pageId),
    fetchPolicy: "no-cache",
    variables: {
      id: decodeURI(pageId),
    },
  });

  useEffect(() => {
    if (data) {
      refetch();
      setCustomerData(data.getCustomerUserById);
    }
  }, [data, refetch]);

  const handleBack = () => {
    history.push("/user-role-management/customer-user");
  };

  return (
    <>
      <Spin spinning={loading}>
        {customerData !== undefined && (
          <CustomerViewPageWrap>
            <Row style={{ marginBottom: "2rem" }}>
              <CustomButton
                onClick={handleBack}
                style={{
                  backgroundColor: "rgb(233,237,242)",
                  borderColor: "black",
                  marginRight: "1rem",
                }}
              >
                <ArrowLeftOutlined />
              </CustomButton>
              <BlackTitle> Back to Table</BlackTitle>
            </Row>
            <SectionWrap>
              <ContentContainer style={{ margin: "0", padding: "0 45px 15px" }}>
                <Sectiontitle style={{ marginBottom: "0" }}>Personal Details</Sectiontitle>
              </ContentContainer>
              <BorderTop />
              <ContentContainer style={{ paddingBottom: "70px" }}>
                <Col>
                  <Row>
                    <Col>
                      <Avatar
                        size={100}
                        style={{
                          backgroundColor: "rgb(29,43,66)",
                          fontSize: "40px",
                          marginRight: "25px",
                        }}
                      >
                        {customerData.first_name.substring(0, 1)}
                      </Avatar>
                    </Col>
                    <Col style={{ marginTop: "1.2rem", marginRight: "auto" }}>
                      <Sectiontitle style={{ marginBottom: "0" }}>
                        {customerData.first_name} {customerData.last_name}
                      </Sectiontitle>
                      <Graytitle>Guest ID: {customerData.guest_id}</Graytitle>
                    </Col>
                    <Col style={{ paddingTop: "1rem", fontSize: "14px" }}>
                      <Status> Active</Status>
                    </Col>
                  </Row>
                </Col>

                <Row style={{ paddingTop: "2.5rem" }}>
                  <Col span={3}>
                    <Graytitle>Mobile Number</Graytitle>
                  </Col>
                  <Col>
                    <BlueTitle>
                      {customerData.country_code}
                      {customerData.mobile_number}
                    </BlueTitle>
                  </Col>
                </Row>
                <Row className="row-content">
                  <Col span={3}>
                    <Graytitle>Email Address</Graytitle>
                  </Col>
                  <Col>
                    <BlueTitle>{customerData.email || "N/A"}</BlueTitle>
                  </Col>
                </Row>
                <Row className="row-content">
                  <Col span={3}>
                    <Graytitle>Date of Birth</Graytitle>
                  </Col>
                  <Col>
                    <BlackTitle>
                      {moment(parseInt(customerData.birth_date)).format("YYYY-MM-DD") || "N/A"}
                    </BlackTitle>
                  </Col>
                </Row>
                <Row className="row-content">
                  <Col span={3}>
                    <Graytitle>Nationality</Graytitle>
                  </Col>
                  <Col>
                    <BlackTitle>
                      {customerData.nationality ? JSON.parse(customerData.nationality).name : "N/A"}
                    </BlackTitle>
                  </Col>
                </Row>
                <Row className="row-content">
                  <Col span={3}>
                    <Graytitle>Gender</Graytitle>
                  </Col>
                  <Col>
                    <BlackTitle> {customerData.gender || "N/A"} </BlackTitle>
                  </Col>
                </Row>
              </ContentContainer>

              <BorderTop />
              <ContentContainer style={{ paddingBottom: "70px" }}>
                <Sectiontitle style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}>
                  Other Details
                </Sectiontitle>
                <Row>
                  <Col span={9}>
                    <Row>
                      <Col span={8}>
                        <Graytitle>Country</Graytitle>
                      </Col>
                      <Col span={8}>
                        <BlackTitle>
                          {customerData.country ? JSON.parse(customerData.country).name : "N/A"}
                        </BlackTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={8}>
                        <Graytitle>Address</Graytitle>
                      </Col>
                      <Col style={{ maxWidth: "200px" }}>
                        <BlackTitle>{customerData.address || "N/A"}</BlackTitle>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={9}>
                    <Row>
                      <Col span={9}>
                        <Graytitle>Anniversary Date</Graytitle>
                      </Col>
                      <Col span={9}>
                        <BlackTitle>{customerData.anniversary || "N/A"}</BlackTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={9}>
                        <Graytitle>Preferences</Graytitle>
                      </Col>
                      <Col span={9}>
                        <BlackTitle>{customerData.preferences}</BlackTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={9}>
                        <Graytitle>Go Rewards No.</Graytitle>
                      </Col>
                      <Col span={9}>
                        <BlackTitle>
                          {customerData.gorewards_loyalty_card_number || "N/A"}
                        </BlackTitle>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={6}>
                    <Row>
                      <Col span={9}>
                        <Graytitle>Type of ID</Graytitle>
                      </Col>
                      <Col span={9}>
                        <BlackTitle>{customerData.id_type || "N/A"}</BlackTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={9}>
                        <Graytitle>Uploaded ID</Graytitle>
                      </Col>
                      <Col span={7}>
                        <BlackTitle>{customerData.id_url}</BlackTitle>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ContentContainer>

              <BorderTop />
              <ContentContainer>
                <Sectiontitle style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}>
                  Logs
                </Sectiontitle>
                <Row>
                  <Col span={7}>
                    <Row>
                      <Col>
                        <BlueTitle>Date/Time Created</BlueTitle>
                        <BlackTitle>
                          {" "}
                          {moment(customerData.created_at).format("YYYY-MM-DD")}
                        </BlackTitle>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={7}>
                    <Row>
                      <Col>
                        <BlueTitle>Last Date/Time Edited</BlueTitle>
                        <BlackTitle>
                          {" "}
                          {moment(customerData.updated_at).format("YYYY-MM-DD")}
                        </BlackTitle>
                        <LightBlueTitle> by User </LightBlueTitle>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <Col>
                        <BlueTitle>Last Logged in</BlueTitle>
                        <BlackTitle> N/A</BlackTitle>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Row>
                      <Col>
                        <BlueTitle>Last Page Visited</BlueTitle>
                        <BlackTitle> N/A</BlackTitle>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "30px", marginTop: "50px" }}>
                  <Col span={7}>
                    <Row>
                      <Col>
                        <BlueTitle>Pages Frequently Visited</BlueTitle>
                        <BlackTitle style={{ maxWidth: "260px" }}> N/A</BlackTitle>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={7}>
                    <Row>
                      <Col>
                        <BlueTitle>Notification Subscription</BlueTitle>
                        <BlackTitle style={{ maxWidth: "260px" }}>
                          {" "}
                          {customerData.promotions == "ENABLED" ? "News Letter" : "N/A"}
                        </BlackTitle>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <Col>
                        <BlueTitle>Platform</BlueTitle>
                        <BlackTitle> MOBILE </BlackTitle>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ContentContainer>
            </SectionWrap>
          </CustomerViewPageWrap>
        )}
      </Spin>
    </>
  );
};

export default CustomerUserView;
