import React, { useState, useEffect, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import moment from "moment";
import ReactExport from "react-data-export";
import { dataTypes } from "./constants";

import { DownloadOutlined } from "@ant-design/icons";
import { Row, Col, Modal, Radio, Space, Avatar, Table, Pagination, Spin, DatePicker } from "antd";
import { DownloadButton } from "./style";
import { useLazyQuery } from "@apollo/client";

import { GET_ANONYMOUS, SEARCH_USER } from "../../../graphql";

import {
  CustomerViewPageWrap,
  ContentContainer,
  SectionWrap,
  Sectiontitle,
  Status,
  Graytitle,
  BlueTitle,
  BlackTitle,
  LightBlueTitle,
} from "./style";
import { Container } from "@/components/ContentContainer/styled";
import { StyledSearch } from "@/components/CustomDashboard/styled";

const AnonymousUserList: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  let match = useRouteMatch();
  const history = useHistory();

  const [customerList, setCustomerList] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [dataLength, setDataLength] = useState(0);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [docFileType, SetdocFileType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedType, setSelectedType] = useState<string[]>(dataTypes.map((type) => type.value));
  const [searchInput, setSearchInput] = useState<string>("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [allGoods, setAllGoods] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [getAnonymous, { data }] = useLazyQuery(GET_ANONYMOUS);
  const [getResult, { data: searchdata }] = useLazyQuery(SEARCH_USER);

  useEffect(() => {
    if (data) {
      const {
        getAnonymousGuests: { customerList: users },
      } = data;

      setCustomerList(users);
      setDataLength(data.getAnonymousGuests.listLength);
      setSelectedData(users);
    }
  }, [data]);

  useEffect(() => {
    getAnonymous({
      variables: {
        page: 1,
        pageSize: 10,
      },
    });
  }, []);

  const onSearchInput = (e) => {
    getResult({
      variables: {
        search: e.toLowerCase(),
      },
    });
  };

  useEffect(() => {
    if (startDate && endDate) {
      setDisabledBtn(false);
      setAllGoods(true);
      console.log(startDate, endDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (searchdata) {
      setSelectedData(searchdata.getCustomerUsersByFilter.customerList);
    }
  }, [searchdata]);

  const onChangeSearchInput = (e) => {
    const { value } = e.target;
    setSearchInput(value);
    if (value === "") {
      setSelectedData(
        customerList.filter((item) => {
          return selectedType.includes(item.__typename);
        })
      );
    } else {
      onSearchInput(value);
    }
  };

  useEffect(() => {
    if (selectedType.length === dataTypes.length) {
      setSelectedData(customerList);
    }

    const filteredData = customerList.filter((item) => {
      return selectedType.includes(item.__typename);
    });
    setSelectedData(filteredData);
    setSearchInput("");
  }, [selectedType]);

  const onChangeFileType = (e) => {
    SetdocFileType(e.target.value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setDownloadLoading(true);
    if (docFileType === "PDF") {
      const response = await fetch(
        `${process.env.REACT_APP_CONTENT_SERVICE}/users/get-anon-customer-list-pdf`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${sessionStorage.getItem("act")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startDate: startDate,
            endDate: endDate,
            customerType: "anonymous",
          }),
        }
      );

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      setDownloadLoading(false);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "document.pdf");

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      try {
        fetch(`${process.env.REACT_APP_CONTENT_SERVICE}/users/get-customer-list-xlsx`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${sessionStorage.getItem("act")}`,
          },
          body: JSON.stringify({
            includeAnonymous: true,
          }),
        })
          .then((response) => response.blob())
          .then((blob) => {
            setDownloadLoading(false);

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "filename.xlsx");
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDisabledBtn(false);
    SetdocFileType("");
  };

  const handleSelectedRow = (id): void => {
    if (!id.includes("auth")) {
      return;
    }
    history.push(`${match.path}/view-user/${encodeURI(id)}`, { pageId: id });
  };

  const columns = [
    {
      title: "Guest ID",
      dataIndex: "guest_id",
      key: "guest_id",
      render: (_, record) => {
        return <BlackTitle>{record.guest_id ? record.guest_id : record.id}</BlackTitle>;
      },
      width: "10%",
    },
    {
      title: "Platform",
      key: "platform",
      render: (_, record) => {
        return <LightBlueTitle>{record.platform ? record.platform : "Mobile"}</LightBlueTitle>;
      },
      width: "10%",
    },
    {
      title: "Last Logged in",
      key: "logged",
      render: (_, record) => {
        return (
          <div>
            <span>
              {record.last_logged_in
                ? moment(record.last_logged_in).format("YYYY-MM-DD HH:mm:ss")
                : moment(record.created_at).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        );
      },
      width: "10%",
    },
  ];

  return (
    <>
      <Modal
        title="Please choose the file type to download"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: disabledBtn }}
        afterClose={() => SetdocFileType("")}
      >
        <Spin spinning={downloadLoading}>
          <Radio.Group value={docFileType} onChange={onChangeFileType}>
            <Space direction="vertical">
              <Row>
                <Col>
                  <Radio
                    value="PDF"
                    name="pdf"
                    onClick={() => {
                      SetdocFileType("PDF");
                      setDisabledBtn(true);
                    }}
                  >
                    As PDF
                  </Radio>
                </Col>
                {disabledBtn || allGoods ? (
                  <Row gutter={10}>
                    <DatePicker
                      placeholder="Start ate"
                      style={{ marginRight: 10 }}
                      onChange={(val) => setStartDate(val?.format("YYYY-MM-DD")!)}
                    />
                    <DatePicker
                      placeholder="End date"
                      style={{ marginRight: 10 }}
                      onChange={(val) => setEndDate(val?.format("YYYY-MM-DD")!)}
                    />
                  </Row>
                ) : null}
              </Row>

              <Radio
                value="EXCEL"
                name="excel"
                onClick={() => {
                  setDisabledBtn(false);
                  SetdocFileType("EXCEL");
                }}
              >
                As Excel
              </Radio>
            </Space>
          </Radio.Group>
          <Space direction="vertical">
            <DownloadOutlined />
            <DownloadOutlined />
          </Space>
        </Spin>
      </Modal>
      <Container>
        <Row style={{ marginBottom: "2rem", paddingTop: "1rem" }}>
          <Col span={7}>
            <StyledSearch
              placeholder={"Search Customer"}
              enterButton
              allowClear
              onSearch={onSearchInput}
              onChange={onChangeSearchInput}
              value={searchInput}
            />
          </Col>
        </Row>
        <Table
          dataSource={
            searchInput
              ? selectedData
              : customerList.filter(({ __typename }) => selectedType.includes(__typename))
          }
          columns={columns}
          pagination={false}
          onRow={(record: any, rowIndex) => {
            return {
              onClick: (event) => handleSelectedRow(record.id),
            };
          }}
        />
        <div style={{ display: "flex", justifyContent: "end", padding: "10px 0" }}>
          <Pagination
            defaultCurrent={1}
            total={dataLength}
            onChange={async (page, pageSize) => {
              getAnonymous({
                variables: {
                  page,
                  pageSize: pageSize ? pageSize : 10,
                },
              });
            }}
          />
        </div>
      </Container>

      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <CustomerViewPageWrap>
            <Row style={{ marginBottom: "2rem" }}></Row>
            {selectedData.map((cus, index) => {
              return (
                <SectionWrap>
                  <div style={{ display: "none" }}>
                    <ExcelFile
                      element={<button className="dloadExcel">Download Data</button>}
                      filename={"Customer Users"}
                    >
                      <ExcelSheet data={selectedData} name="Customer Users">
                        <ExcelColumn
                          label="Guest ID"
                          bold={true}
                          value={(col) => (col.guest_id ? col.guest_id : col.id)}
                        />
                        <ExcelColumn
                          label="First Name"
                          value={(col) => (col.first_name ? col.first_name : col.name)}
                        />
                        <ExcelColumn label="Last Name" value="last_name" />
                        <ExcelColumn label="Suffix" value="suffix" />
                        <ExcelColumn label="Gender" value="gender" />
                        <ExcelColumn label="Mobile Number" value="mobile_number" />
                        <ExcelColumn label="Email" value="email" />
                        <ExcelColumn
                          label="Date of Birth"
                          value={(col) => moment(parseInt(col.birth_date)).format("YYYY-MM-DD")}
                        />
                        <ExcelColumn
                          label="Nationality"
                          value={(col) =>
                            col.nationality ? JSON.parse(col.nationality).name : "N/A"
                          }
                        />
                        <ExcelColumn
                          label="Country"
                          value={(col) => (col.country ? JSON.parse(col.country)?.name : "N/A")}
                        />
                        <ExcelColumn label="Address" value="address" />
                        <ExcelColumn
                          label="Go Rewards Card Number"
                          value="gorewards_loyalty_card_numberes"
                        />
                        <ExcelColumn label="ID Type" value="id_type" />
                        <ExcelColumn label="ID URL" value="id_url" />
                        <ExcelColumn label="Created at" value="created_at" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>

                  <ContentContainer style={{ margin: "0", padding: "0 45px" }}>
                    <Sectiontitle style={{ marginBottom: "0" }}>Personal Details</Sectiontitle>
                  </ContentContainer>

                  <ContentContainer>
                    <Col>
                      <Row>
                        {/*  */}
                        <Col>
                          <Avatar
                            size={100}
                            style={{
                              backgroundColor: "rgb(29,43,66)",
                              fontSize: "40px",
                              marginRight: "25px",
                            }}
                          >
                            {cus.first_name?.substring(0, 1) || "N/A"}
                          </Avatar>
                        </Col>
                        <Col style={{ marginTop: "1.2rem", marginRight: "auto" }}>
                          <Sectiontitle style={{ marginBottom: "0" }}>
                            {cus.first_name ? cus.first_name : cus.name} {cus.last_name}
                          </Sectiontitle>
                          <Graytitle>Guest ID: {cus.guest_id ? cus.guest_id : cus.id}</Graytitle>
                        </Col>
                        <Col style={{ paddingTop: "1rem", fontSize: "14px" }}>
                          <Status>ACTIVE </Status>
                        </Col>
                      </Row>
                    </Col>

                    <Row style={{ paddingTop: "2.5rem" }}>
                      <Col span={5}>
                        <Graytitle>Mobile Number</Graytitle>
                      </Col>
                      <Col>
                        <BlueTitle>
                          {cus.country_code}
                          {cus.birth_date}
                        </BlueTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Email Address</Graytitle>
                      </Col>
                      <Col>
                        <BlueTitle>{cus.email || "N/A"}</BlueTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Date of Birth</Graytitle>
                      </Col>
                      <Col>
                        <BlackTitle>
                          {moment(parseInt(cus.birth_date)).format("YYYY-MM-DD") || "N/A"}
                        </BlackTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Nationality</Graytitle>
                      </Col>
                      <Col>
                        <BlackTitle>
                          {cus.nationality ? JSON.parse(cus.nationality).name : "N/A"}
                        </BlackTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Gender</Graytitle>
                      </Col>
                      <Col>
                        <BlackTitle>{cus.gender || "N/A"}</BlackTitle>
                      </Col>
                    </Row>
                  </ContentContainer>
                  <ContentContainer style={{ paddingBottom: "10px" }}>
                    <Sectiontitle style={{ marginBottom: "1.5rem" }}>Other Details</Sectiontitle>
                    <Row>
                      <Col span={9}>
                        <Row>
                          <Col span={8}>
                            <Graytitle>Country</Graytitle>
                          </Col>
                          <Col span={8}>
                            <BlackTitle>
                              {cus.country ? JSON.parse(cus.country)?.name : "N/A"}
                            </BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={8}>
                            <Graytitle>Address</Graytitle>
                          </Col>
                          <Col span={10}>
                            <BlackTitle>{cus.address || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={9}>
                        <Row>
                          <Col span={12}>
                            <Graytitle>Anniversary Date</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.anniversary || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={9}>
                            <Graytitle>Preferences</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.preferences || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={11}>
                            <Graytitle>Go Rewards No.</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.gorewards_loyalty_card_number || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={6}>
                        <Row>
                          <Col span={11}>
                            <Graytitle>Type of ID</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.id_type || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={11}>
                            <Graytitle>Uploaded ID</Graytitle>
                          </Col>
                          <Col span={7}>
                            <BlackTitle>{cus.id_url || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ContentContainer>

                  <ContentContainer>
                    <Sectiontitle style={{ marginBottom: "1.5rem" }}>Logs</Sectiontitle>
                    <Row>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Date/Time Created</BlueTitle>
                            <BlackTitle>
                              {moment(cus.created_at).format("YYYY-MM-DD") || "N/A"}
                            </BlackTitle>
                            <LightBlueTitle> </LightBlueTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Last Date/Time Edited</BlueTitle>
                            <BlackTitle>
                              {" "}
                              {moment(cus.created_at).format("YYYY-MM-DD") || "N/A"}{" "}
                            </BlackTitle>
                            <LightBlueTitle> by {cus.first_name} </LightBlueTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row>
                          <Col>
                            <BlueTitle>Last Logged in</BlueTitle>
                            <BlackTitle>N/A </BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row>
                          <Col>
                            <BlueTitle>Last Page Visited</BlueTitle>
                            <BlackTitle> N/A</BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: "30px", marginTop: "50px" }}>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Pages Frequently Visited</BlueTitle>
                            <BlackTitle style={{ maxWidth: "260px" }}> N/A</BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Notification Subscription</BlueTitle>
                            <BlackTitle style={{ maxWidth: "260px" }}>
                              {" "}
                              {cus.promotions == "ENABLED" ? "News Letter" : "N/A"}{" "}
                            </BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row>
                          <Col>
                            <BlueTitle>Platform</BlueTitle>
                            <BlackTitle> MOBILE </BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ContentContainer>
                </SectionWrap>
              );
            })}
          </CustomerViewPageWrap>
        </div>
      </div>
      <Row justify="end">
        <Col>
          <DownloadButton onClick={showModal}>DOWNLOAD FILE</DownloadButton>
        </Col>
      </Row>
    </>
  );
};

export default AnonymousUserList;
