import React from "react";
import { Link, useParams } from "react-router-dom";
import { Menu } from "antd";

import logo from "../../assets/images/logos/logo-white-rhr.svg";
import BookIcon from "../../assets/images/icons/ic_library_books.svg";
import BusinessIcon from "../../assets/images/icons/ic_business.svg";
import FooterManagementIcon from "../../assets/images/icons/ic_footer_management.svg";
import NotificationIcon from "../../assets/images/icons/ic_notification.svg";
import UserRoleManagementIcon from "../../assets/images/icons/ic_user.svg";

import { LogoTemp, LogoWrapper, StyledLogo, StyledSider, SubMenuWrap } from "./styled";

interface Props {
  collapsed: boolean;
}

const Navigation: React.FC<Props> = ({ collapsed }) => {
  const { page, subPage } = useParams();
  return (
    <StyledSider trigger={null} width="250" collapsible collapsed={collapsed}>
      <LogoWrapper>
        {collapsed ? <LogoTemp /> : <StyledLogo src={logo} alt="Robinsons Hotels and Resorts" />}
      </LogoWrapper>

      <Menu theme="dark" mode="inline" selectedKeys={[page, subPage]}>
        <Menu.Item
          key="brand-management"
          icon={<img className="cust-icon" src={BusinessIcon} alt="business icon" />}
        >
          <Link to={"/brand-management"}>Brand Management</Link>
        </Menu.Item>
        <SubMenuWrap
          key="homepage-management"
          title="Home Page Management"
          icon={<img className="cust-icon" src={BookIcon} alt="book icon" />}
        >
          <Menu.Item key="rhr-hero-banner">
            <Link to="/home-page-management/rhr-hero-banner">RHR Hero Banner</Link>
          </Menu.Item>
          <Menu.Item key="promos-and-packages">
            <Link to="/home-page-management/promos-and-packages">Promos and Packages</Link>
          </Menu.Item>
          <Menu.Item key="updates-announcement">
            <Link to="/home-page-management/updates-and-announcements">
              Updates and Announcements
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="feature-content">
            <Link href="#">Featured Content</Link>
          </Menu.Item> */}
        </SubMenuWrap>

        <SubMenuWrap
          key="user-role-management"
          title="User Role Management"
          icon={<img className="cust-icon" src={UserRoleManagementIcon} alt="business icon" />}
        >
          <Menu.Item key="cms-user-management">
            <Link to="/user-role-management/cms-user-management">CMS User Management</Link>
          </Menu.Item>
          <Menu.Item key="customer-user">
            <Link to="/user-role-management/customer-user">Customer User</Link>
          </Menu.Item>
          <Menu.Item key="anonymous-user">
            <Link to="/user-role-management/anonymous-user">Anonymous User</Link>
          </Menu.Item>
          <Menu.Item key="role-management">
            <Link to="/user-role-management/role-management">Role Management</Link>
          </Menu.Item>
        </SubMenuWrap>

        <SubMenuWrap
          key="footer-management"
          title="Footer Management"
          icon={
            <img className="cust-icon" src={FooterManagementIcon} alt="Footer Management Icon" />
          }
        >
          <Menu.Item key="rhr-informations">
            <Link to="/footer-management/rhr-informations">RHR Information</Link>
          </Menu.Item>
        </SubMenuWrap>

        <SubMenuWrap
          key="notification-settings"
          title="Notification Settings"
          icon={<img className="cust-icon" src={NotificationIcon} alt="Footer Management Icon" />}
        >
          <Menu.Item key="promotions">
            <Link to="/notification-settings/promotions">Promotions</Link>
          </Menu.Item>
          <Menu.Item key="notifications">
            <Link to="/notification-settings/notifications">Notifications</Link>
          </Menu.Item>
        </SubMenuWrap>
      </Menu>
    </StyledSider>
  );
};

export default Navigation;
