import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Menu, Dropdown } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";

import CustomModal from "@/components/CustomModal";

import { GET_USER_INFO, REMOVE_USER_TOKEN } from "./graphql";
import { UserI } from "models/UserInformationInterface";
import { StyledHeader, SiderButton, NameLink, MenuContainer } from "./styled";

import LogoutIcon from "../../assets/images/icons/ic_logout.svg";

interface Props {
  collapsed: boolean;
  toggle: () => void;
}

const LayoutHeader: React.FC<Props> = ({ collapsed, toggle }) => {
  const [showModal, setShowModal] = useState(false);
  const [userInfo, setUserInfo] = useState<UserI>();
  const { data } = useQuery(GET_USER_INFO);
  const [removeUserToken] = useMutation(REMOVE_USER_TOKEN);

  useEffect(() => {
    if (data) {
      setUserInfo(data.getUserInfo);
    }
  }, [data]);

  const UserMenu = () => {
    return (
      <MenuContainer>
        <Menu selectable={false}>
          <Menu.Item
            key="1"
            icon={<img className="cust-icon" src={LogoutIcon} alt="Logout Icon" />}
          >
            <Link onClick={() => setShowModal(true)}>Logout</Link>
          </Menu.Item>
        </Menu>
      </MenuContainer>
    );
  };

  const logoutHandler = async () => {
    const removeToken = await removeUserToken();
    if (removeToken.data?.invalidateUserToken) {
      sessionStorage.removeItem("act");
      window.location.replace(`
        ${process.env.REACT_APP_AUTH0_REDIRECT_URL}
      `);
    }
  };

  const handleOk = () => {
    setShowModal(false);
    logoutHandler();
  };
  return (
    <>
      <StyledHeader style={{ padding: 0 }}>
        <SiderButton onClick={() => toggle()}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </SiderButton>
        <div style={{ padding: "0 25px" }}>
          <Dropdown overlay={UserMenu}>
            <NameLink className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <Avatar size={26} icon={<UserOutlined />} />
              <span style={{ marginLeft: 7 }}> {userInfo?.name} </span>
            </NameLink>
          </Dropdown>
        </div>
      </StyledHeader>
      <CustomModal
        isWarningIcon
        visible={showModal}
        titleMessage="Logout"
        message={"Are you sure you want to logout?"}
        onOk={handleOk}
        onCancel={() => setShowModal(false)}
        okText="Logout"
        maskClosable={false}
      />
    </>
  );
};

export default LayoutHeader;
