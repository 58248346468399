import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CmsUserList from "./components/cms-user-management/cms-user-list";
import CMSUserManagementForm from "./components/cms-user-management/cms-user-form";
import CMSUserManagementView from "./components/cms-user-management/cms-user-view";
import CustomerUserList from "./components/customer-user/customer-user-list";
import CustomerUserView from "./components/customer-user/customer-user-view";
import RolesManagementList from "./components/role-management/role-management-list";
import RolesManagementForm from "./components/role-management/role-management-form";
import RoleManagementView from "./components/role-management/role-management-view";
import AnonymousUserList from "./components/anonymous-user/customer-user-list";

const UserRoleManagement: React.FC = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.path}/cms-user-management`}
          render={(props: any) => <CmsUserList {...props} />}
        />
        <Route
          exact
          path={`${match.path}/cms-user-management/add-user`}
          render={(props: any) => <CMSUserManagementForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/cms-user-management/edit-user/:pageId`}
          render={(props: any) => <CMSUserManagementForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/cms-user-management/view-user/:pageId`}
          render={(props: any) => <CMSUserManagementView {...props} />}
        />
        <Route
          exact
          path={`${match.path}/customer-user`}
          render={(props: any) => <CustomerUserList {...props} />}
        />
        <Route
          exact
          path={`${match.path}/anonymous-user`}
          render={(props: any) => <AnonymousUserList {...props} />}
        />
        <Route
          exact
          path={`${match.path}/customer-user/view-user/:pageId`}
          render={(props: any) => <CustomerUserView {...props} />}
        />
        {/* <Route
          exact
          path={`${match.path}/role-management`}
          render={(props: any) => <RolesManagementList {...props} />}
        />
        <Route
          exact
          path={`${match.path}/role-management/add-role`}
          render={(props: any) => <RolesManagementForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/role-management/edit-role/:pageId`}
          render={(props: any) => <RolesManagementForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/role-management/view-role/:pageId`}
          render={(props: any) => <RoleManagementView {...props} />}
        /> */}
      </Switch>
    </>
  );
};

export default UserRoleManagement;
